<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">WORLDWIDE TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">
					<h2>Worldwide Travel Insurance</h2>
					<p class="mb-5">
						If you are planning a trip outside of Europe, we would recommend that you purchase a worldwide travel insurance policy. 
					</p>

					<h2>Why choose worldwide travel insurance?</h2>
					<p class="mb-3">
						When it comes to travel plans outside of Europe, it is worth making sure you are covered with a worldwide policy. 
					</p>
					<p class="mb-5">
						Whether you are planning a round-the-world trip or a beach escape in the Caribbean, we can help protect your travel arrangements.  
					</p>

					<h2>Do I need single trip or annual multi-trip travel insurance for a worldwide holiday?</h2>
					<p class="mb-3">
						Depending one whether you are looking to cover a one-off worldwide holiday, or multiple trips will help you decide which type of travel insurance policy you should choose. 
					</p>
					<p class="mb-5">
						If you do have multiple trips planned, then a multi-trip policy may be more economical. On our policies, you can travel as often as you like, for trips up to 31 days each in duration. If any of your trips are longer than 31 days, then we do have the option to extend your cover to 45 and 60 days should you need it.  
					</p>

					<h2>What is the difference between UK, Europe, and worldwide travel insurance?</h2>
					<p class="mb-4">
						Covered2go provide cover for trips to the UK, Europe and worldwide. 
					</p>
					<p class="mb-5">
						<ul>
							<li><strong>UK travel insurance </strong> is for those who are planning a trip within the UK and wish to cover their holiday.</li>
							<li><strong>Europe travel insurance </strong> travel insurance is for those who are planning a trip to Europe, though our European territory does cover countries outside of Europe, such as, Egypt. </li>
							<li><strong>Worldwide travel insurance </strong>, as the name suggests, provides cover for the rest of the world. However, our policies do separate worldwide into, ‘worldwide excluding USA, Canada and the Caribbean, and ‘worldwide including USA, Canada and the Caribbean’.</li>
						</ul>
					</p>

					<h2>Why do I need worldwide travel insurance?</h2>
					<p class="mb-3">
						If you have worldwide trips planned, it is well worth having, just in case the following happens:
					</p>
					<p class="mb-5">
						<ul>
							<li>You need to cancel your trip last minute.</li>
							<li>You have an accident and require emergency medical assistance.</li>
							<li>Your personal belongings get lost, stolen, or damaged.</li>
						</ul>
					</p>

					<h2>What are the main benefits of having worldwide travel insurance?</h2>
					<p class="mb-3">
						There are many benefits which are policies can provide you with, such as:
					</p>
					<p class="mb-5">
						<ul>
							<li><strong>Emergency medical assistance </strong> – if you have an accident or fall ill abroad (including contracting Covid-19*), our policies will cover you for hospital expenses and repatriation back to the UK, if needed. We provide you with three choices of tiered cover, and our medical expenses benefits range from £5 Million to £10 Million. </li>
							<li><strong>Lost, stolen or damaged luggage </strong> – our policies will pay up to £5,000 for your personal possessions, if they get lost, stolen or damaged. If you have mobile phones and tablets which need cover too, you may want to choose our Platinum cover which includes cover for expensive gadgets. </li>
							<li><strong>Flight delays </strong> – your airline should always be the first port of call if you need compensation from flights if they are cancelled or delayed. If your airline does not compensate or cover you, our policy may be able to cover you. For example, if your flight is delayed by 12 hours or more, our policies will pay you up to £50 per 12 hours you are delayed for, up to a maximum of £500. </li>
							<li><strong>Cancellation and curtailment </strong> – all our policies include cover for cancellation and curtailment. This means you may be able to claim back money for your holiday if you have to cancel unexpectedly. Or if you needed to return home from your holiday early, our policies may pay out for unused portion of your trip. Please check the policy terms and conditions on our <a href="/policy-wordings-mul">policy wordings</a> for further information. </li>
							<li><strong>Personal liability </strong> – every country has different laws, so personal liability could come in handy. Our policies provide personal liability cover up to £2 Million. </li>
						</ul>
					</p>

					<h2>What is not covered by worldwide travel insurance?</h2>
					<p class="mb-3">
						While your worldwide travel insurance will cover your worldwide trip, there are still some exclusions you should be aware of, such as: 
					</p>
					<p class="mb-5">
						<ul>
							<li>Our policies will not cover you if you travel against government or FCDO advice. </li>
							<li>Medical care and treatment for pre-existing medical conditions, if these have not been disclosed to our medical screening partners. <a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">Click here</a> for more information on cover your pre-existing medical conditions. </li>
							<li>Treatment for illnesses which you should have been vaccinated for before you travelled. </li>
							<li><a href="https://www.covered2go.co.uk/sports-activities-cover">Sports and activities</a> which are high risk. Our policies do provide cover for many sports and activities as standard; however, it is important to check if you need cover for riskier activities. You can add your sport or activity to your policy when you get your quote. </li>
						</ul>
					</p>

					<h2>Is there anything else I should consider covering on my worldwide travel insurance policy?</h2>
					<p class="mb-3">
						Depending on the type of trip you have planned, will depend on the extra cover you may need to add to your policy. You can also add the following to your policy if you need to:
					</p>
					<p class="mb-5">
						<ul>
							<li><strong>Golf cover </strong> – if you are planning a golfing trip, then you may want to consider adding golf cover to your policy. This will provide you with the added benefit for protecting your golfing equipment. </li>
							<li><strong>Winter sports cover </strong> – if you are planning a skiing or snowboarding trip, then it is important you add winter sports cover to your policy. This will provide you with the additional benefits of protecting your ski or snowboard equipment, whether they are owned by yourself or hired in resort, we will cover them if they get lost, stolen or damaged.</li>
							<li><strong>Cruise cover </strong> – if you are planning a cruise, then it is important to add cruise cover to your policy. Cruise cover will provide you with cruise-specific benefits, such as, cabin confinement and missed port departure.</li>
							<li><strong>Business cover </strong> – if you are planning a business trip, you are covered to do non-manual work during your trip under this policy and it can be extended to cover manual work. Business Cover will also protect your business equipment if it is lost, stolen, damaged or destroyed. If you are unable to fulfil your business trip, due to illness, we will cover the costs for a replacement employee to take over</li>
						</ul>
					</p>

					<p class="mb-4">
						Looking for worldwide travel insurance? <a href="https://www.covered2go.co.uk/get-quote">Get a quote</a> online today.
					</p>

					<p class="mb-4">
						*Please refer to the Policy Wording for terms, conditions, and exclusions. 
					</p>
					
				</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'WorldwideTravelInsurance',
	data() {
		return {

		}
	},
	methods: {

	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
